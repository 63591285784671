import { initAccordionTeasers } from "../../template-parts/blocks/accordion-teaser/script";
import { initAccordion } from "../../template-parts/blocks/accordions/script";
import { initHeroWideImage } from "../../template-parts/blocks/hero-wide-image/script";
import { initHistorySlider } from "../../template-parts/blocks/history-slider/script";
import { initLocalSitePicker } from "../../template-parts/blocks/local-site-picker/script";
import { initPostListWithFilter } from "../../template-parts/blocks/post-list-with-filter/script";
import { initProductIntro } from "../../template-parts/blocks/product-intro/script";
import { initStoryCarousel } from "../../template-parts/blocks/stories/script";
import { initSustainabilityCalculator } from "../../template-parts/blocks/sustainability-calculator/script";
import { initCo2Calculator } from "../../template-parts/blocks/co2-calculator/script";
import { initTestimonials } from "../../template-parts/blocks/testimonials/script";
import { initStaffListCards } from "../../template-parts/blocks/staff-list/script";
import { initOfficeList } from "../../template-parts/blocks/office-list/script";
import { initOpenJobPositions } from "../../template-parts/blocks/open-job-positions/script";
import { initServiceListGrid } from "../../template-parts/blocks/service-list-grid/script";
import { initTabbedContent } from "../../template-parts/blocks/tabbed-content/script";
import { whenEditorIsReady } from "./utils";

// these functions run on page load

/**
 * Reason: Mobile browsers treat vh unit differently than desktop ones
 * This function calculate vh unit in pixel, and set to css variable so it can be reused consistently
 */
const setVh = () => {
    // get actual 1vh value in pixel
    let vh = window.innerHeight * 0.01;
    // Set the value to the --vh variable at the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
};

setVh();
window.addEventListener("resize", setVh);

/**
 * Reason: Delay heavy-but-not-critical script execution (e.g. Interactive Geo Maps plugin) on page load, in order not blocking the main thread
 * After user interacts with the page, or after a certain timeout, trigger script manually
 * Idea referred from the Flying Scripts plug-in
 */
const loadBlockingScripts = () => {
    initMaps();
};

const initMaps = () => {
    // check if the maps plugin and its dependencies are defined
    // if not, try again after 0.25s
    if (
        typeof am4maps === "undefined" ||
        typeof am4themes_animated === "undefined" ||
        typeof iMaps === "undefined"
    ) {
        setTimeout(initMaps, 250);
        return;
    }

    // init map when everything is ready
    iMaps.init(false);
};

const triggerLoadingScripts = () => {
    loadBlockingScripts();
    // clear trigger timeout threshold
    clearTimeout(loadScriptsTimer);
    // remove user interaction listener
    userInteractionEvents.forEach((event) => {
        window.removeEventListener(event, triggerLoadingScripts, {
            passive: true,
        });
    });
};

// default loading the scripts after 5s
const SCRIPT_TIMEOUT = 5000;
const loadScriptsTimer = setTimeout(triggerLoadingScripts, SCRIPT_TIMEOUT);
const userInteractionEvents = [
    "mouseover",
    "keydown",
    "touchmove",
    "touchstart",
];

userInteractionEvents.forEach((event) => {
    window.addEventListener(event, triggerLoadingScripts, { passive: true });
});

const blocksWithScript = {
    "acf/accordions": initAccordion,
    "acf/accordion-teaser": initAccordionTeasers,
    "acf/hero-wide-image": initHeroWideImage,
    "acf/history-slider": initHistorySlider,
    "acf/sustainability-calculator": initSustainabilityCalculator,
    "acf/co2-calculator": initCo2Calculator,
    "acf/local-site-picker": initLocalSitePicker,
    "acf/post-list-with-filter": initPostListWithFilter,
    "acf/product-intro": initProductIntro,
    "acf/stories": initStoryCarousel,
    "acf/testimonials": initTestimonials,
    "acf/staff-list": initStaffListCards,
    "acf/office-list": initOfficeList,
    "acf/open-job-positions": initOpenJobPositions,
    "acf/service-list-grid": initServiceListGrid,
    "acf/tabbed-content": initTabbedContent,
};

/**
 * Editor: Trigger the block's JS when added
 */
const triggerBlockScriptInEditor = () => {
    if (typeof wp === "undefined" || !wp.blocks) {
        return;
    }

    const { getBlocks, getClientIdsOfDescendants } =
        wp.data.select("core/block-editor");
    // Get current blocks client ids
    let blockList = getBlocks();

    wp.data.subscribe(() => {
        // Get new blocks client ids
        const newBlockList = getBlocks();
        // Quit if there is no change in the block list (e.g. when user just wrote new text)
        const blockListChanged = newBlockList !== blockList;
        if (!blockListChanged) {
            return;
        }

        const newBlockIds = newBlockList.map((block) => block.clientId);
        // The editor need some time to render after a block is added
        whenEditorIsReady()?.then(() => {
            const addedBlocks = newBlockIds.filter(
                (blockId) => !blockList.includes(blockId)
            );

            // scan through the list of added blocks, and trigger their corresponding scripts
            addedBlocks.forEach((addedBlockId) => {
                const innerBlockIds = getClientIdsOfDescendants([addedBlockId]);

                [addedBlockId, ...innerBlockIds].forEach((blockClientId) => {
                    triggerBlockScriptById(blockClientId);
                    addBlockChangeListener(blockClientId);
                });
            });

            // Update current block list with the new blocks for further comparison
            blockList = newBlockList;
        });
    });
};

/**
 * Trigger block script when its acf field value change
 * @param {string} blockClientId
 * @returns
 */
const addBlockChangeListener = (blockClientId) => {
    const { getBlockName } = wp.data.select("core/block-editor");
    const blockWrapper = document.querySelector(`#block-${blockClientId}`);
    const blockName = getBlockName(blockClientId);

    if (!Object.keys(blocksWithScript).includes(blockName)) {
        return;
    }

    const observer = new MutationObserver((list) => {
        // prevent the init function to get triggered too many times
        if (blockWrapper?.dataset["eventAdded"]) {
            return;
        }

        // A change in ACF field will rerender the block
        // rerender means the mutation list only contain two items: remove .acf-block-preview & add it again
        if (
            list.length === 2 &&
            list[0].removedNodes.length === 1 &&
            list[1].addedNodes.length === 1
        ) {
            blocksWithScript[blockName](blockWrapper);
        }
    });
    observer.observe(blockWrapper, {
        childList: true,
        subtree: true,
    });

    blockWrapper.setAttribute("data-event-added", true);
};

/**
 *
 * @param {String} blockClientId
 * @returns
 */
const triggerBlockScriptById = (blockClientId) => {
    const checkPreviewReadyInterval = setInterval(() => {
        if (typeof wp === "undefined" || !wp.blocks) {
            return;
        }

        const { getBlockName } = wp.data.select("core/block-editor");
        const blockName = getBlockName(blockClientId);
        if (!blocksWithScript[blockName]) {
            clearInterval(checkPreviewReadyInterval);
            return;
        }

        const blockWrapper = document.querySelector(
            `#block-${blockClientId} .acf-block-preview > *:first-child`
        );

        if (!blockWrapper) {
            return;
        }

        if (blockWrapper?.dataset["eventAdded"]) {
            return;
        }

        blocksWithScript[blockName](blockWrapper);
        clearInterval(checkPreviewReadyInterval);
        blockWrapper.setAttribute("data-event-added", true);
    }, 100);
};

triggerBlockScriptInEditor();

/**
 * Set Hubspot script if not exist
 * Wait for Hubspot script if not initialized
 */
function initHubspot() {
    if (!document.getElementById("hubspot-script")) {
        const scriptTag = document.createElement("script");
        scriptTag.setAttribute("src", "//js.hsforms.net/forms/embed/v2.js");
        scriptTag.setAttribute("id", "hubspot-script");
        document.head.appendChild(scriptTag);
    }

    if (typeof hbspt !== "undefined") {
        return;
    }

    setTimeout(initHubspot, 250);
}

initHubspot();
